
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCommonSupplement} from "@/models/app/common/supplement";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Search} from "@element-plus/icons-vue";

@Options({
  props: [
    "supplement",
  ],
  components: {
    ElDialog,
    ElButton,
    Search,
  },
})
export default class AppPlayerResourcesRootSupplementsFormLookupVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  supplement: AppCommonSupplement = new AppCommonSupplement();
}
